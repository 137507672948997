















































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { BatteryEntityModel, BatteryQueryModel } from './model/battery-entity';
import BatteryService from './service/battery';
import BatteryLinkDialog from './battery-link.vue';

@Component({
    components: {
        'battery-link-dialog': BatteryLinkDialog
    }
})
export default class BatteryListComponent extends TableDialogFormComponent<BatteryEntityModel, BatteryQueryModel> {
    BatteryEntityModel = BatteryEntityModel;
    created() {
        this.initTable({
            service: BatteryService,
            queryModel: new BatteryQueryModel(),
            tableColumns: BatteryEntityModel.getTableColumns()
        });
        this.getList();
    }

    linkClick(model: BatteryEntityModel) {
        (this.$refs.linkDialog as BatteryLinkDialog).dialogOpen(model?.id);
    }

    downloadExcelClick() {
        BatteryService.downloadExcelTemplate();
    }

    importExcelChange(fileList) {
        if (fileList && fileList.length > 0) {
            this.startFullScreenLoading('正在导入引导牌...');
            return BatteryService.importExcel(_.get(fileList, '[0].file')).then(res => {
                this.showMessageSuccess('导入引导牌成功');
            }).finally(() => {
                this.getList();
                this.stopFullScreenLoading();
            });
        }
    }
}

